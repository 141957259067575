import React from "react";
import Footer from "../component/Footer/Footer";
import Header from "../component/Header/Header";
import useFetch from "../ApiClient/GetApi";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../Utils/urls";
import Timetable from "../component/Timetable/Timetable";
import { Box, Container, Flex, Heading, Text } from '@chakra-ui/react';


export default function PlanDetails() {
  const param = useParams();
  const navigate = useNavigate();
  const { data } = useFetch(`users/get_plan_details/${param.planId}`);
  console.log(data.data);
  const join_now = (planId) => {
    navigate(`/join_now/${planId}`);
  };
  return (
    <div>
      <Header />

      <div>Plan Details Page</div>
      <div> Title : {data?.data?.title}</div>
      <div> Price : {data?.data?.price}</div>
      <div>
        {" "}
        Short Description :{" "}
        <div
          dangerouslySetInnerHTML={{ __html: data?.data?.shortDescription }}
        />
      </div>
      <div>
        {" "}
        Long Description :{" "}
        <div
          dangerouslySetInnerHTML={{ __html: data?.data?.longDescription }}
        />
      </div>
      <div>
        Image : <img src={`${BASE_URL}${data?.data?.image}`} />
      </div>
      {/* <div>
        <button
          className="btn btn-success"
          onClick={() => join_now(data?.data?.id)}
        >
          Join Now
        </button>
      </div> */}
      <div></div>
    
    <Box className="heading-page header-text" style={{background:"black",color:"white",padding:"80px ",opacity:"4"}}>
      <Box as="section" className="page-heading">
        <Container >
          <Box className="row">
            <Box className="col-lg-12" >
              <Box className="text-content">
                <Heading as="h4" size="md">Title</Heading>
                <Heading as="h2" size="xl">{data?.data?.title}</Heading>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>

    <Box as="section" className="call-to-action" style={{marginTop:"20px"}}>
      <Container style={{background:"gray", padding:"20px"}}>
        <Flex align="center" justify="space-between">
          <Box className="main-content">
            <Flex align="center">
              <Box flex="1">
                <Text fontSize="lg">Stand Blog HTML5 Template</Text>
                <Heading as="h4" size="md">Creative HTML Template For Bloggers!</Heading>
              </Box>
              <Box>
                <Box className="main-button" style={{background:"green",padding:"10px"}}>
                  <a rel="nofollow"  onClick={() => join_now(data?.data?.id)}>Join Now!</a>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
      <Footer />
    </div>
  );
}
