import { Box, HStack, VStack, Icon, Text, Avatar } from "@chakra-ui/react";
import { useState } from "react";
import Logo from "../../assets/Fitlogo.png";
import { BellIcon, ChatIcon, InfoIcon, HamburgerIcon } from "@chakra-ui/icons";

const Navbar = () => {
  const [showOptions, setShowOptions] = useState(false);

  const handleIconClick = () => {
    setShowOptions(!showOptions);
  };
  return (
    <Box
      className="navbar"
      p="0.5rem"
      borderBottom="1px"
      borderColor="gray.200"
      backgroundColor="black"
    >
      <HStack justify="space-between">
        {/* <Box mr={2}>
          <img src={Logo} alt="Logo" h={2} />
        </Box> */}

        <HStack spacing="1.5rem">
          <Box
            className="item"
            color="white"
            display="flex"
            alignItems="center"
            cursor="pointer"
            _hover={{ color: "blue.500" }}
          >
            <Icon as={InfoIcon} boxSize={5} className="icon" />
          </Box>
          <Box
            className="item"
            display="flex"
            color="white"
            alignItems="center"
            cursor="pointer"
            _hover={{ color: "blue.500" }}
          >
            <Icon as={BellIcon} boxSize={4} className="icon" />
            <Box className="counter">1</Box>
          </Box>
          <Box
            className="item"
            display="flex"
            color="white"
            alignItems="center"
            cursor="pointer"
            _hover={{ color: "blue.500" }}
          >
            <Icon as={ChatIcon} boxSize={4} className="icon" />
            <Box className="counter">2</Box>
          </Box>
          <Box
            className="item"
            display="flex"
            color="white"
            alignItems="center"
            cursor="pointer"
            _hover={{ color: "blue.500" }}
          >
            <Icon
              as={HamburgerIcon}
              boxSize={6}
              className="icon"
              onClick={handleIconClick}
            />
            {showOptions && (
              <VStack
                position="absolute"
                top="100%"
                right="0"
                backgroundColor="white"
                boxShadow="lg"
                p="4"
                zIndex={999}
              >
                <Text>Option 1</Text>
                <Text>Option 2</Text>
                <Text>Option 3</Text>
              </VStack>
            )}
          </Box>
          <Box className="item">
            <Avatar
              size="sm"
              src="https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
              alt=""
            />
          </Box>
        </HStack>
      </HStack>
    </Box>
  );
};

export default Navbar;
