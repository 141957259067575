import React from "react";
import Navbar from "../component/Dasboard/navbarDashboard";
import SideBar from "../component/SidebarDasboard/SideBar";

export const Chatpage = () => {
  return (
    <div>
      <Navbar />
      <SideBar />
    </div>
  );
};
export default Chatpage;
