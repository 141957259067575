import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import Planes from "./pages/planes";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import UserAdminPannel from "./pages/UserAdminPannel";
import Chatpage from "./pages/Chatpage";
import Services from "./pages/Services";
import PlansPage from "./pages/plansPage";
import { ToastContainer } from "react-toastify";
import ProtectedRoutes from "./Utils/ProtectedRoutes";
import PlanDetails from "./pages/PlanDetails";
import MyPlans from "./pages/MyPlans";
import Workout from "./component/Plans/Workout";
import Contact from "./pages/Contact";

function App() {
  return (
    <div>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route
          path="/planes"
          element={<ProtectedRoutes Component={Planes} />}
        />
        <Route
          path="/workout"
          element={<ProtectedRoutes Component={Workout} />}
        />
        <Route
          path="/plan_details/:planId"
          element={<ProtectedRoutes Component={PlanDetails} />}
        />
        <Route
          path="/Services"
          element={<ProtectedRoutes Component={Services} />}
        />
        <Route
          path="/my-plans"
          element={<ProtectedRoutes Component={MyPlans} />}
        />
        <Route path="/Login" element={<Login />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/join_now/:planId" element={<UserAdminPannel />} />
        <Route
          path="/Chatpage"
          element={<ProtectedRoutes Component={Chatpage} />}
        />
        <Route
          path="/PlansPage"
          element={<ProtectedRoutes Component={Planes} />}
        />
        <Route
          path="/contact"
          element={<Contact />}
        />
      </Routes>
    </div>
  );
}

export default App;
// {/* <div className="App">

//      <Route exact path="/planes"component={planes}/>

//      <Programs/>
//       <Resons/>
//       <Plans/>
//       <Testimonials/>
//       <Join/>
//       <Footer/>
// </div> */}
