import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChakraProvider } from '@chakra-ui/react';

ReactDOM.render(

  <React.StrictMode>
      <BrowserRouter>
      <ChakraProvider>

        <App />
        <ToastContainer />
        </ChakraProvider>

      </BrowserRouter>
  </React.StrictMode>
,
  document.getElementById("root")
);
