import React from "react";
import { Box, Heading, Text, Flex, Badge } from "@chakra-ui/react";
import { GiHealthNormal } from "react-icons/gi";
import { MdFitnessCenter } from "react-icons/md";
import "./service.css"
import servicesImage from "../../assets/Services.png"
import Header from "../Header/Header";

const service = (props) => {
  console.log(props.services)
  return (
    <div>
    <Header />
    <div className="container">
      <img src={servicesImage} alt="" />
      <div class="overlay">
        <span class="row1">Plans</span>
        <br />
        <span class="row2">&</span>
        <span class="row2">Services</span>
      </div>
    </div>
    
    <Box p={8}>
      <Heading
        as="h1"
        mb={8}
        textAlign="center"
        fontSize="3xl"
        fontWeight="bold"
      >
        Services
      </Heading>

      <Flex direction="row" alignItems="start" flexWrap="wrap" mb={8}>
       {
        props?.services?.map((service)=>(
          <Box
          bgGradient="linear( white, #b8c933)"
          color="black"
          p={6}
          borderRadius="md"
          shadow="lg"
          display="flex"
          alignItems="center"
          mb={4}
          width={{ base: "100%", md: "50%" }}
          transition="all 0.3s"
          _hover={{ transform: "scale(1.02)" }}
        >
          <Box as={GiHealthNormal} size={96} color="#b8c933" mr={6} />
          <Flex direction="column">
            <Heading
              as="h2"
              size="xl"
              mb={4}
              fontFamily="sans-serif"
              fontWeight="bold"
            >
             {service.title}
            </Heading>
            <Text fontFamily="sans-serif">
            {service.desc}
            </Text>
          </Flex>
        </Box>
        ))
       }

       

        {/* Add more service sections as needed */}
      </Flex>

      <Box textAlign="center">
        <Badge
          colorScheme="#b8c933"
          variant="outline"
          px={4}
          py={2}
          fontSize="xl"
          fontWeight="bold"
        >
          Get Started Today
        </Badge>
      </Box>
    </Box>
    </div>
  );
};
export default service;
