import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Collapse,
  Link,
  Button,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/Fitlogo.png";
import { FaWhatsapp } from "react-icons/fa";
import "./Header.css";

const Header = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsHeaderScrolled(true);
      } else {
        setIsHeaderScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleTransitionEnd = () => {
      setIsAnimating(false);
    };

    window.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      window.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, []);

  const currentPage = location.pathname;

  let headerClassName = "";
  // Set different backgrounds based on the current page
  if (currentPage === "/") {
    headerClassName = "header-home";
  } else {
    headerClassName = "not-header-home";
  }

  const handlePositionChange = () => {
    setIsAnimating(true);
  };

  const logout = () =>{
    localStorage.removeItem("accessToken");
    localStorage.removeItem("LoginStatus");
    navigate("/login")
  }

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      px={7}
      py={3}
      boxShadow="md"
      bg={
        isHeaderScrolled
          ? "rgba(0, 0, 0, 0.5)"
          : headerClassName === "header-home"
          ? "transparent"
          : "rgba(0, 0, 0, 0.5)"
      }
      color="white"
      position={isHeaderScrolled ? "fixed" : "relative"}
      top={0}
      left={0}
      right={0}
      zIndex={isHeaderScrolled ? "10" : "auto"}
      className={headerClassName}
      style={{
        transition: "background-color 0.3s ease, top 0.3s ease",
        animationPlayState: isAnimating ? "running" : "paused",
      }}
      onAnimationEnd={handlePositionChange}
    >
      <Box mr={2}>
        <Link as={RouterLink} to="/">
          <Image src={Logo} alt="Logo" h={14} />
        </Link>
      </Box>

      <Box mt={2} display={{ base: "none", md: "block" }}>
        <Flex as="ul" listStyleType="none">
          <NavItem to="/">Home</NavItem>
          <NavItem to="/Services">Services</NavItem>
          <NavItem to="/contact">Contact Us</NavItem>
          <NavItem to="/planes">Plans</NavItem>
          <NavItem to="/workout">Workout</NavItem>
          <NavItem to="/my-plans">My Plans</NavItem>
          <NavItem to="/How-it-works">How it works</NavItem>
          <NavItem to="/Products">Products</NavItem>
          <NavItem to="/testimonials">Testimonials</NavItem>
        </Flex>
      </Box>
      <Box display={{ base: "block", md: "none" }}>
        <IconButton
          icon={isMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Menu"
          variant="ghost"
          onClick={toggleMenu}
        />
      </Box>

      <Box display={{ base: "none", md: "block" }}>
        <Flex align="center">
          <Link
            href="https://wa.me/+447477182176"
            title="Click to contact on WhatsApp"
            isExternal
          >
            <IconButton
              mt={3}
              icon={<FaWhatsapp />}
              aria-label="WhatsApp"
              variant="ghost"
              fontSize="25px"
              _hover={{ cursor: "pointer" }}
            />
          </Link>
          <Box
            mr={4}
            mt={3}
            display="flex"
            alignItems="center"
            title="Click to contact on WhatsApp"
            color="white"
            ml={1}
            _hover={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => window.open("https://wa.me/+447477182176")}
          >
            +44 (747) 718-2176
          </Box>
          {localStorage.getItem("LoginStatus") ? (
            <div>
              {" "}
              <p>
                {localStorage.getItem("firstName")}{" "}
                {localStorage.getItem("lastName")}
              </p>{" "}
              <Button
                mt={2}
                colorScheme="#b8c933"
                variant="outline"
                bg="white"
                fontSize="15px" // Adjust the font size here
                fontFamily="Arial, sans-serif"
                color="#b8c933"
                mr={4}
                _hover={{ bg: "#b8c933", color: "white" }}
                onClick={logout}
              >
                Logout
              </Button>
            </div>
          ) : (
            <div>
              <Link as={RouterLink} to="/login">
                <Button
                  mt={2}
                  colorScheme="#b8c933"
                  variant="outline"
                  bg="white"
                  fontSize="15px" // Adjust the font size here
                  fontFamily="Arial, sans-serif"
                  color="#b8c933"
                  mr={4}
                  _hover={{ bg: "#b8c933", color: "white" }}
                >
                  Login
                </Button>
              </Link>
              <Link mt={2} as={RouterLink} to="/signup">
                <Button
                  colorScheme="#b8c933"
                  color="white"
                  fontSize="15px" // Adjust the font size here
                  fontFamily="Arial, sans-serif"
                  bg="#b8c933"
                  _hover={{ color: "#b8c933", bg: "white" }}
                >
                  Sign Up
                </Button>
              </Link>
            </div>
          )}
        </Flex>
      </Box>
      <Collapse in={isMenuOpen} animateOpacity>
        <Box mt={2} display={{ base: "block", md: "none" }}>
          <Flex
            as="ul"
            listStyleType="none"
            direction="column"
            alignItems="center"
          >
            <NavItem to="/">Home</NavItem>
            <NavItem to="/planes">Planes</NavItem>
            <NavItem to="/prices">Prices</NavItem>
            <NavItem to="/why-us">Why Us</NavItem>
            <NavItem to="/How-it-works">How it works</NavItem>
            <NavItem to="/Products">Products</NavItem>
            <NavItem to="/testimonials">Testimonials</NavItem>
          </Flex>
        </Box>
      </Collapse>
    </Flex>
  );
};

const NavItem = ({ to, children }) => (
  <Box as="li" mr={4} mt={2}>
    <Link
      as={RouterLink}
      to={to}
      fontWeight="medium"
      _hover={{ textDecoration: "underline", color: "#b8c933" }}
      transition="0.3s"
    >
      {children}
    </Link>
  </Box>
);

export default Header;
