import { FaRibbon } from 'react-icons/fa';
import { FaBaby } from 'react-icons/fa';
import { FaTint } from 'react-icons/fa';
import { FaWeight } from 'react-icons/fa';
const DietPlanData = [
{
  icon: <FaRibbon />,
    heading: "PCOS/PCOD",
    details: "We are offering personalized diet plans that promote hormonal balance, manage weight, and address related symptoms such as irregular periods and excess hair growth.",
   
    features: [
      
     
    ],
  },
  {  
    icon: <FaBaby />,
    heading: "Postpartum Recovery",
    details: "women during the postpartum period, offering nutritional guidance to aid in recovery, replenish nutrient stores, support breastfeeding if applicable, and promote overall well-being.",
    features: [
    
    ],
  },
  {  
    icon: <FaTint />,
    heading: "Thyroid Health",
    details: "Women with thyroid issues, providing recommendations to support thyroid function, manage weight, and alleviate symptoms such as fatigue and mood swings.",
    features: [
     
    ],
  },{  
    icon: <FaWeight />,
    heading: "Stuck Weight Issues",
    details: "Weight loss plateaus or difficulty losing weight, providing strategies to overcome barriers and break through sticking points to achieve sustainable progress.",
    features: [
  
    ],
  },
  {
    icon: <FaRibbon />,
      heading: "PCOS/PCOD",
      details: "We are offering personalized diet plans that promote hormonal balance, manage weight, and address related symptoms such as irregular periods and excess hair growth.",
     
      features: [
        
       
      ],
    },
  // Add more diet plans as needed
];
export default DietPlanData;