import React, { useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { VStack } from "@chakra-ui/layout";
import ForgotPassword from "../forgetPassword/ForgetPassword";
import { useToast } from "@chakra-ui/react";
import { PostApi } from "../../ApiClient/PostApi";
import { setLoginStatus } from "../../Utils/AuthCheck";
import { error_toaster, success_toaster } from "../../Utils/Taoster";
import { Loader2 } from "../../pages/Loader";
export const Loginhome = () => {
  
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const navigate = useNavigate();
  const [showForgetPassword, setShowForgetPassword] = useState(false);

  const handleForgetPasswordClick = () => {
    setShowForgetPassword(true);
  };

  const login = async () => {
    if (email === "") {
      error_toaster("Email is required!");
      return false;
    }
    if (password === "") {
      error_toaster("Password is required!");
      return false;
    }
    setLoading(true);
    const res = await PostApi("users/login", {
      email: email,
      password: password,
    });

    if (res.data.status === "1") {
      navigate("/");
      setLoading(false);
      setLoginStatus(true);
      localStorage.setItem("BearerToken", res.data.data.accessToken);
      localStorage.setItem("firstName", res.data.data.firstName);
      localStorage.setItem("lastName", res.data.data.lastName);
      localStorage.setItem("email", res.data.data.email);
      localStorage.setItem("userId", res.data.data.id);

      success_toaster(res.data.message);
    } else {
      setLoading(false);
      error_toaster(res.data.message);
    }
  };

  return (
    <div>
      {loading ? (
        <Loader2 />
      ) : (
        <VStack spacing="10px">
          <FormControl id="email" isRequired>
            <FormLabel>Email Address</FormLabel>
            <Input
              value={email}
              type="email"
              placeholder="Enter Your Email Address"
              onChange={(e) => setEmail(e.target.value)}
              variant="filled"
              _hover={{ borderColor: "blue.500" }}
              _focus={{ borderColor: "blue.500" }}
            />
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel>Password</FormLabel>
            <InputGroup size="md" width="100%">
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={show ? "text" : "password"}
                placeholder="Enter password"
                variant="filled"
                _hover={{ borderColor: "blue.500" }}
                _focus={{ borderColor: "blue.500" }}
                width="100%" // Set a fixed width for the input
              />
              <InputRightElement width="4.5rem" height="100%">
                <Button
                  h="100%" // Match the height of the input
                  size="sm"
                  onClick={handleClick}
                  variant="ghost"
                  colorScheme="blue"
                  _hover={{ background: "transparent" }}
                >
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Button
            colorScheme="blue"
            width="100%"
            style={{ marginTop: 15 }}
            onClick={login}
            isLoading={loading}
            _hover={{ background: "blue.500" }}
          >
            Login
          </Button>
          <div>
            {/* Your login form */}
            {/* ... */}

            {/* Forget Password Button */}
            <Button
              colorScheme="blue"
              width="100%"
              marginTop={4}
              onClick={handleForgetPasswordClick}
              _hover={{ background: "blue.500" }}
            >
              Forget Password
            </Button>

            {/* Render ForgetPassword component if showForgetPassword is true */}
            {showForgetPassword && <ForgotPassword />}
          </div>
        </VStack>
      )}
    </div>
  );
};
export default Loginhome;
