import React, { useState } from "react";
import "./userAdminPannel.css";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../ApiClient/GetApi";
import { PostApi } from "../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../Utils/Taoster";
import { Loader2 } from "./Loader";

export const UserAdminPannel = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data } = useFetch(`users/get_plan_details/${params.planId}`);

  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryyear] = useState("");
  const [cvv, setCVV] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    if (cardNumber === "") {
      error_toaster("Card Number is required!");
      return false;
    }
    if (name === "") {
      error_toaster("Name is required!");
      return false;
    }
    if (expiryMonth === "") {
      error_toaster("Expiry Month is required!");
      return false;
    }
    if (expiryYear === "") {
      error_toaster("Expiry Year is required");
      return false;
    }
    if (cvv === "") {
      error_toaster("Cvc is required");
      return false;
    }
    setLoading(true);
    const res = await PostApi("users/stripe_payment", {
      cvc: cvv,
      name: name,
      cardNo: cardNumber,
      planId: data?.data?.id,
      amount: data?.data?.price,
      exp_year: expiryYear,
      exp_month: expiryMonth,
    });
    if (res.data.status === "1") {
      success_toaster(res.data.message);
      setLoading(false);
      navigate("/");
    } else {
      error_toaster(res.data.message);
      setLoading(false);
    }
    // Handle payment submission and processing logic here
  };

  return (
    <div className="payment-page">
      <h2 className="payment-heading">Payment Details</h2>
      <p>Plan Title : {data?.data?.title ?? ""}</p>
     {
      loading ? <Loader2/>: <form onSubmit={handlePaymentSubmit}>
      <div className="form-group">
        <label>Name on Card:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label>Card Number:</label>
        <input
          type="text"
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label>Expiry Year:</label>
        <select onChange={(e) => setExpiryyear(e.target.value)}>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
          <option value="2026">2026</option>
          <option value="2027">2027</option>
          <option value="2028">2028</option>
          <option value="2029">2029</option>
        </select>
      </div>
      <div className="form-group">
        <label>Expiry Month:</label>
        <select onChange={(e) => setExpiryMonth(e.target.value)}>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
        </select>
      </div>

      <div className="form-group">
        <label>CVV:</label>
        <input
          type="text"
          value={cvv}
          onChange={(e) => setCVV(e.target.value)}
          required
        />
      </div>
      <button type="submit" className="submit-button">
        ${data?.data?.price} Make Payment
      </button>
    </form>
     }
    </div>
  );
};

export default UserAdminPannel;
