import React from "react";
import "./Plans.css";
import { plansData } from "../../data/plansData";
import { plansDatapakistan } from "../../data/plansData";
import { Box, Text, Image, Button } from "@chakra-ui/react";
import whiteTick from "../../assets/whiteTick.png";
import { useNavigate } from "react-router-dom";
const Plans = (props) => {
  const navigate = useNavigate();
  const plan_details = (planId) =>{
   navigate(`/plan_details/${planId}`)
  }
  return (
    <div>
      <Box textAlign="center" mb={10} mt={12}>
        <Text fontSize="2xl" fontWeight="bold">
          Ready to Start Your Journey Now With Us
        </Text>
        <Text fontSize="2xl" fontWeight="bold">
          Workout Plan Starting from 20
        </Text>
      </Box>
      <Box display="flex" justifyContent="center" gap={8} padding={"0px 20px"} flexWrap={"wrap"}>
        {
         
        props?.plans?.map((plan, i) => (
          <Box
            key={i}
            p={6}
            bg="white"
            color="black"
            borderRadius="md"
            boxShadow="md"
            transition="all 0.3s"
            _hover={{ transform: "scale(1.02)" }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            width={{ base: "100%", md: "50%", lg: "33.33%" }}
            margin="1rem"
            padding="1rem"
          >
           
            <Text mt={4} fontSize="xl" fontWeight="bold">
              {plan.title}
            </Text>
            <Text mt={2} fontSize="lg">
              $ {plan.price}
            </Text>
            <Box
              mt={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              
                <Box key={i} display="flex" alignItems="center" mb={2}>
                  <Image src={whiteTick} alt="" boxSize={4} mr={2} />
                  <Text> <div dangerouslySetInnerHTML={{ __html: plan?.shortDescription }} /></Text>
                </Box>
             
            </Box>
            <Button
              mt={4}
              colorScheme="green"
              borderRadius="full"
              px={8}
              py={4}
              fontSize="lg"
              fontWeight="bold"
              onClick={()=>plan_details(plan.id)}
            >
              Join Now
            </Button>
          </Box>

        ))} 
      </Box>
      {/* <Box textAlign="center" mb={10} mt={12}>
        <Text fontSize="2xl" fontWeight="bold">
          Ready to Start Your Journey Now With Us
        </Text>
        <Text fontSize="2xl" fontWeight="bold">
          Dietplan Starting from 20
        </Text>
      </Box> */}
      {/* <Box display="flex" justifyContent="center" gap={8}>
        {plansDatapakistan.map((plan, i) => (
          <Box
            key={i}
            p={6}
            bg="white"
            color="black"
            borderRadius="md"
            boxShadow="md"
            transition="all 0.3s"
            _hover={{ transform: "scale(1.02)" }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            width={{ base: "100%", md: "50%", lg: "33.33%" }}
            margin="1rem"
            padding="1rem"
          >
            {plan.icon}
            <Text mt={4} fontSize="xl" fontWeight="bold">
              {plan.name}
            </Text>
            <Text mt={2} fontSize="lg">
              $ {plan.price}
            </Text>
            <Box
              mt={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              {plan.features.map((feature, i) => (
                <Box key={i} display="flex" alignItems="center" mb={2}>
                  <Image src={whiteTick} alt="" boxSize={4} mr={2} />
                  <Text>{feature}</Text>
                </Box>
              ))}
            </Box>
            <Button
              mt={4}
              colorScheme="green"
              borderRadius="full"
              px={8}
              py={4}
              fontSize="lg"
              fontWeight="bold"
            >
              Join Now
            </Button>
          </Box>
        ))}
      </Box> */}
    </div>
  );
};

export default Plans;
