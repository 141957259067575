import React from "react";
import Header from "../component/Header/Header";
import useFetch from "../ApiClient/GetApi";
import Plans from "../component/Plans/Plans";
import { Box, Button, Image, Text } from "@chakra-ui/react";
import whiteTick from "../assets/whiteTick.png";
import Moment from "react-moment";
export default function MyPlans() {
  const { data } = useFetch("users/get_user_plans");
  console.log(data?.data);


    const join_meeting = (link) =>{
    window.open(link, '_blank');
  }
  return (
    <>
      <Header />
      <div>
        <Box
          display="flex"
          justifyContent="center"
          gap={8}
          padding={"0px 20px"}
          flexWrap={"wrap"}
        >
          {data?.data?.map((plan, i) => (
            <Box
              key={i}
              p={6}
              bg="white"
              color="black"
              borderRadius="md"
              boxShadow="md"
              transition="all 0.3s"
              _hover={{ transform: "scale(1.02)" }}
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              width={{ base: "100%", md: "50%", lg: "33.33%" }}
              margin="1rem"
              padding="1rem"
            >
              <Text mt={4} fontSize="xl" fontWeight="bold">
                {plan.Plan.title}
              </Text>
              <Text mt={2} fontSize="lg">
              Total Price :  ${plan.price}
              </Text>
              <Box
                mt={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box key={i} display="flex" alignItems="center" mb={2}>
                  <Image src={whiteTick} alt="" boxSize={4} mr={2} />
                  <Text>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: plan?.Plan.shortDescription,
                      }}
                    />
                  </Text>
                </Box>
              </Box>
              <Button
                mt={4}
                colorScheme="green"
                borderRadius="full"
                px={8}
                py={4}
                fontSize="lg"
                fontWeight="bold"
                
              >
                Join Date :{" "}
                <Moment format="D MMM , YYYY hh:mm a">{plan.buyingDate}</Moment>
              </Button>
              <Button
                mt={4}
                colorScheme="green"
                borderRadius="full"
                px={8}
                py={4}
                fontSize="lg"
                fontWeight="bold"
                
              >
                Expire Date :{" "}
                <Moment format="D MMM , YYYY hh:mm a">{plan.expireDate}</Moment>
              </Button>
              {
                plan.Plan.CategoryId == 2 ?<Button
                mt={4}
                colorScheme="green"
                borderRadius="full"
                px={8}
                py={4}
                fontSize="lg"
                fontWeight="bold"
                onClick={()=>join_meeting(plan.Plan.zoomLink)}
              >
               Join Now on Zoom Link
                
              </Button>:""
              }
            </Box>
          ))}
        </Box>
      </div>
    </>
  );
}
