import React, { useEffect } from "react";
import {
  Box,
  Container,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import "./login.css";
import Signup from "./Signup";
import Loginhome from "../component/Loginhome/Loginhome";
import { getLoginStatus } from "../Utils/AuthCheck";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (getLoginStatus() === true) {
      navigate("/");
    }
  });

  return (
    <Box bg="white" h="100vh">
      <Container maxW="xl" centerContent>
        <Box
          d="flex"
          justifyContent="center"
          p={3}
          bg="black"
          w="100%"
          m="40px 0 15px 0"
          borderRadius="lg"
          borderWidth="1px"
        >
          <Text
            fontSize="4xl"
            fontFamily="Work Sans"
            textAlign="center"
            fontWeight="bold"
            color="#b8c933"
          >
            Fit Her
          </Text>
        </Box>
        <Box bg="white" w="100%" p={4} borderRadius="lg" borderWidth="1px">
          <Tabs isFitted variant="soft-rounded" colorScheme="whatsapp">
            <TabList mb="1em">
              <Tab width="50%">Login</Tab>
              <Tab width="50%">Sign Up</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Loginhome />
              </TabPanel>
              <TabPanel>
                <Signup />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Container>
    </Box>
  );
  //  return (

  //     <form className=" login-form">
  //       {console.log("user: ", user)};

  //         <div
  //           style={{
  //             textAlign: "center",
  //             fontSize: "2rem",
  //             color: "white",
  //             marginbottom: "20px",
  //           }}
  //         >
  //           Login{" "}
  //         </div>

  //       <label htmlFor="username">Username / Email</label>
  //       <input
  //         type="email"
  //         name="email"
  //         value={user.email}
  //         onChange={handleChange}
  //       />
  //       <label htmlFor="password">Password</label>
  //       <input
  //         type="password"
  //         name="password"
  //         value={user.password}
  //         onChange={handleChange}
  //       />
  //       <button type="submit">Login</button>
  //       <div
  //         style={{ textAlign: "center", fontSize: "1.19rem", color: "white" }}
  //       >
  //         or
  //       </div>
  //       <button type="submit">Sign up</button>
  //       <div style={{ textAlign: "center", fontSize: "1rem", color: "white" }}>
  //         If you don't have account on "FitHer"
  //       </div>
  //     </form>

  // );
};
export default Login;
