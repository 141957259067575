import React, { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to an API
    console.log(formData);
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Contact Us</h2>
      <img
        src="https://www.moorevisions.com/wp-content/uploads/2015/12/Contact-us-banner.jpg"
        alt="Contact Us"
        style={styles.image}
      />
      <div style={styles.row}>
        <form onSubmit={handleSubmit} style={styles.form}>
          <input
            type="text"
            name="fullName"
            placeholder="Full Name"
            value={formData.fullName}
            onChange={handleChange}
            style={styles.input}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone"
            value={formData.phone}
            onChange={handleChange}
            style={styles.input}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            style={styles.input}
            required
          />
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
            style={styles.input}
            required
          />
          <textarea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            style={styles.textarea}
            required
          />
          <button type="submit" style={styles.button}>Send Message</button>
        </form>
        <div style={styles.mapContainer}>
          <h3 style={styles.mapHeader}>Our Location</h3>
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345091024!2d144.95373531531688!3d-37.81720997975165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f5d2f45%3A0x5045675218ceed0!2sYour%20Location!5e0!3m2!1sen!2sau!4v1638400987700!5m2!1sen!2sau"
            style={styles.map}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "1200px",
    margin: "20px auto",
    padding: "20px",
    backgroundColor: "#e8f5e9",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  },
  header: {
    textAlign: "center",
    color: "#388e3c",
  },
  image: {
    width: "100%",
    borderRadius: "8px",
    marginBottom: "20px",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  form: {
    flex: 1,
    marginRight: "20px",
  },
  input: {
    margin: "10px 0",
    padding: "10px",
    border: "1px solid #c8e6c9",
    borderRadius: "4px",
    fontSize: "16px",
    width: "100%",
  },
  textarea: {
    margin: "10px 0",
    padding: "10px",
    border: "1px solid #c8e6c9",
    borderRadius: "4px",
    fontSize: "16px",
    height: "100px",
    resize: "none",
    width: "100%",
  },
  button: {
    padding: "10px",
    backgroundColor: "#388e3c",
    color: "#ffffff",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    width: "100%",
  },
  mapContainer: {
    flex: 1,
  },
  mapHeader: {
    textAlign: "center",
    color: "#388e3c",
  },
  map: {
    width: "100%",
    height: "300px",
    border: "0",
  },
};

export default Contact;