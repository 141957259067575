import {
  Box,
  Divider,
  Text,
  Flex,
  List,
  ListItem,
  Link as ChakraLink,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom";
import {
  FiHome,
  FiCreditCard,
  FiUsers,
  FiCalendar,
  FiPackage,
  FiSettings,
  FiMessageCircle,
  FiLogOut,
} from "react-icons/fi";

const SideBar = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    setLoading(true);

    try {
      await axios.post("http://localhost:8000/users/logout");
      toast({
        title: "Logged out successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      // Redirect to the desired page after successful logout
      navigate("/login"); // Replace "/login" with the appropriate logout page or login page
    } catch (error) {
      toast({
        title: "Error occurred",
        description: error.response.data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
    }
  };
  return (
    <Box width="250px" height="100vh" bg="Black" p={4}>
      <Flex align="center" mb={4}>
        <Text fontSize="xl" color="white" fontWeight="bold" mr={2}>
          THE
        </Text>
        <Text fontSize="xl" color="#b8c933" fontWeight="bold">
          FITHER
        </Text>
      </Flex>
      <Divider mb={4} />
      <List spacing={7}>
        <ListItem>
          <Icon color="white" as={FiHome} mr={2} />
          <ChakraLink color="white" as={RouterLink} to="/Dashboard/home">
            Home
          </ChakraLink>
        </ListItem>
        <ListItem>
          <Icon color="white" as={FiMessageCircle} mr={2} />
          <ChakraLink color="white" as={RouterLink} to="/Dashboard/chat">
            Chat
          </ChakraLink>
        </ListItem>
        <ListItem>
          <Icon color="white" as={FiCalendar} mr={2} />
          <ChakraLink color="white" as={RouterLink} to="/Dashboard/schedule">
            Schedule
          </ChakraLink>
        </ListItem>
        <ListItem>
          <Icon color="white" as={FiUsers} mr={2} />
          <ChakraLink color="white" as={RouterLink} to="/Dashboard/members">
            Members
          </ChakraLink>
        </ListItem>
        <ListItem>
          <Icon color="white" as={FiCalendar} mr={2} />
          <ChakraLink color="white" as={RouterLink} to="/Dashboard/schedule">
            Schedule
          </ChakraLink>
        </ListItem>
        <ListItem>
          <Icon color="white" as={FiPackage} mr={2} />
          <ChakraLink color="white" as={RouterLink} to="/Dashboard/products">
            Products
          </ChakraLink>
        </ListItem>
        <ListItem>
          <Icon color="white" as={FiCreditCard} mr={2} />
          <ChakraLink color="white" as={RouterLink} to="/Dashboard/payment">
            Payment
          </ChakraLink>
        </ListItem>
        <ListItem>
          <Icon color="white" as={FiSettings} mr={2} />
          <ChakraLink color="white" as={RouterLink} to="/Dashboard/settings">
            Settings
          </ChakraLink>
        </ListItem>
      </List>
      <Divider mt={4} mb={4} />
      <List spacing={3}>
        <ListItem>
          <Icon color="white" as={FiLogOut} mr={2} />
          <ChakraLink
            onClick={handleLogout}
            color="white"
            as={RouterLink}
            to="/logout"
          >
            Logout
          </ChakraLink>
        </ListItem>
      </List>
    </Box>
  );
};

export default SideBar;
