import React, { useState } from "react";
import "./Testimonials.css";
import { testimonialsData } from "../../data/testimonialsData";
import leftArrow from "../../assets/leftArrow.png";
import rightArrow from "../../assets/rightArrow.png";
import { motion } from "framer-motion";

const Testimonials = () => {
  const transition = { type: "spring", duration: 3 };
  const [selected, setSelected] = useState(0);
  const tlength = testimonialsData.length;

  const handlePrevious = () => {
    setSelected((prev) => (prev === 0 ? tlength - 1 : prev - 1));
  };

  const handleNext = () => {
    setSelected((prev) => (prev === tlength - 1 ? 0 : prev + 1));
  };

  const testimonial = testimonialsData[selected];

  return (
    <div className="testimonials">
      <div className="left-t">
        <h2>Testimonials</h2>
        <h3>What They Say About Us?</h3>
        <motion.p
          key={selected}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{ ...transition, duration: 2 }}
        >
          {testimonial.review}
        </motion.p>
        <p>
          <span style={{ color: "var(--green)" }}>{testimonial.name}</span> -{" "}
          {testimonial.status}
        </p>
      </div>
      <div className="right-t">
        <motion.div
          initial={{ opacity: 0, x: -120 }}
          transition={{ ...transition, duration: 3 }}
          whileInView={{ opacity: 1, x: 0 }}
        ></motion.div>
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          transition={{ ...transition, duration: 3 }}
          whileInView={{ opacity: 1, x: 0 }}
        ></motion.div>
        <img src={testimonial.image} alt="" />

        <div className="arrow">
          <img onClick={handlePrevious} src={leftArrow} alt="Previous" />
          <img onClick={handleNext} src={rightArrow} alt="Next" />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
