import React from "react";
import { DietPlanProgram } from "../component/DietPlanProgram/DietPlanProgram";
import Header from "../component/Header/Header";
import Programs from "../component/Programs/Programs";
import Service from "../component/Service/service";
import ServicesComponent from "../component/WHY-us/Servicecompoent";
import useFetch from "../ApiClient/GetApi";

const Services = () => {

  const {data} = useFetch("users/all_services");
  console.log(data)
  
  return (
    <div>

      <Service services={data?.data} />
      <ServicesComponent/>
      <DietPlanProgram/>
      <Programs/>
    </div>
  );
};
export default Services;
