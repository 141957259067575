import React, { useState } from "react";
import axios from "axios";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleForgotPassword = async () => {
    try {
      setLoading(true);
      setError("");

      // Make API request to send a password reset email
      const response = await axios.post(
        "http://localhost:8000/users/forgotpassword",
        { email }
      );

      setMessage(response.data.message);
      setEmail("");
    } catch (error) {
      setError("Something went wrong,  try again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <p>Enter your email address to receive a password reset link.</p>
      {message && <p>{message}</p>}
      {error && <p>{error}</p>}
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={handleForgotPassword} disabled={loading}>
        {loading ? "Loading..." : "Submit"}
      </button>
    </div>
  );
};

export default ForgotPassword;
