import React, { useRef } from "react";
import "./Join.css";
import emailjs from "@emailjs/browser";

export const Join = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_43f1rwq",
        "template_nurcys8",
        form.current,
        "3FKXEN_k7GAGQiQJX"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
          <span>Ready to </span>
          <span className="stroke-text">Level UP</span>
        </div>
        <div>
          <span className="stroke-text">Your Body</span>
          <span> With US?</span>
        </div>
      </div>
      <div className="right-j">
        <form
          ref={form}
          action=""
          className="email-container"
          onSubmit={sendEmail}
        >
          <input
            type="email"
            name="user_email"
            placeholder="Enter your email address"
          />
          <button className="btn btn-j">join now</button>
        </form>
      </div>
    </div>
  );
};
export default Join;
