import React from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import {
  FaDumbbell,
  FaUtensils,
  FaBell,
  FaClock,
  FaChartLine,
  FaVideo,
  FaQuestionCircle,
} from "react-icons/fa";

const ServicesComponent = () => {
  return (
    <Flex direction="row" justifyContent="center" py={4}>
      <Flex
        maxW="lg"
        direction="row"
        alignItems="center"
        justifyContent="center"
        // mt={4}
      >
        <ServiceBox
          icon={<FaDumbbell size={48} color="#b8c933" />}
          title="Online Workout"
          description="Personal attention and customized workout plans."
        />

        <ServiceBox
          icon={<FaUtensils size={48} color="#b8c933" />}
          title="Customized Meal Plan"
          description="Tailored meal plans designed to meet your specific goals."
        />

        <ServiceBox
          icon={<FaBell size={48} color="#b8c933" />}
          title="Daily Class Reminder"
          description="Stay on track with daily class reminders and notifications."
        />

        <ServiceBox
          icon={<FaClock size={48} color="#b8c933" />}
          title="Flexible Time Slots"
          description="Choose from flexible time slots that fit your schedule."
        />

        <ServiceBox
          icon={<FaChartLine size={48} color="#b8c933" />}
          title="Progress Tracking"
          description="Track progress over time with measurements, photos, and fitness assessments."
        />

        <ServiceBox
          icon={<FaVideo size={48} color="#b8c933" />}
          title="Online Fitness Library"
          description="Access a library of workout videos and exercise tutorials."
        />

        <ServiceBox
          icon={<FaQuestionCircle size={48} color="#b8c933" />}
          title="Q&A Sessions"
          description="Host live Q&A sessions with fitness experts to address member questions and concerns."
        />
      </Flex>
    </Flex>
  );
};

const ServiceBox = ({ icon, title, description }) => {
  return (
    <Box
      w="250px"
      p={4}
      borderRadius="lg"
      boxShadow="md"
      bg="white"
      textAlign="center"
      transition="transform 0.3s ease"
      _hover={{ transform: "scale(1.05)" }}
      mx={4} // Add horizontal margin to separate the ServiceBox components
    >
      {icon}
      <Text mt={4} fontWeight="bold">
        {title}
      </Text>
      <Text mt={2}>{description}</Text>
    </Box>
  );
};

export default ServicesComponent;
