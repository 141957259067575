import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./signup.css";

import { PostApi } from "../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../Utils/Taoster";
import { Loader2 } from "./Loader";
import { setLoginStatus } from "../Utils/AuthCheck";

export const Signup = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    reEnterPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };
  const [errorMessage, setErrorMessage] = useState("");

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  
  const signup = async (e) => {
    
    e.preventDefault();
  
    if (user.firstName === "") {
      error_toaster("First Name is required!");
      return false;
    }
    if (user.lastName === "") {
      error_toaster("Last Name is required!");
      return false;
    }
    if (user.phone === "") {
      error_toaster("Phone is required!");
      return false;
    }
    if (user.email === "") {
      error_toaster("Email is required!");
      return false;
    }
    if(!validateEmail(user.email))
    {
      error_toaster("Email is not valid!");
      return false;
    }
    if (user.password === "") {
      error_toaster("Password required!");
      return false;
    }
    if (user.reEnterPassword === "") {
      error_toaster("Confirm Password is required!");
      return false;
    }

    if(user.password !== user.reEnterPassword)
    {
      error_toaster("Confirm Password is mismatch!");
      return false;
    }

    setLoading(true);
    const res = await PostApi("users/registration", {
      email: user.email,
      phone: user.phone,
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password,
    });
    if (res.data.status === "1") {
      navigate("/");
      setLoading(false);
      setLoginStatus(true);
      localStorage.setItem("BearerToken", res.data.data.accessToken);
      localStorage.setItem("firstName", res.data.data.firstName);
      localStorage.setItem("lastName", res.data.data.lastName);
      localStorage.setItem("email", res.data.data.email);
      localStorage.setItem("userId", res.data.data.id);
      success_toaster(res.data.message);
    } else {
      error_toaster(res.data.message);
      setLoading(false);
    }
  };
  return (
    <div>
      {loading ? (
        <Loader2 />
      ) : (
        <form className="signuplogin-form">
          {console.log(user, setUser)}
          <div
            style={{
              textAlign: "center",
              fontSize: "2rem",
              color: "var(--green)",
            }}
          >
            Sign Up{" "}
          </div>
          <label>First Name</label>
          <input
            type="text"
            name="firstName"
            value={user.firstName}
            onChange={handleChange}
          />
          <label>Last Name</label>
          <input
            type="text"
            name="lastName"
            value={user.lastName}
            onChange={handleChange}
          />
          <label>Phone</label>
          <input
            type="text"
            name="phone"
            id="phone"
            required
            value={user.phone}
            onChange={handleChange}
          />
          <label>Email</label>
          <input
            type="email"
            name="email"
            id="email"
            required
            value={user.email}
            onChange={handleChange}
          />
          <label>Password</label>
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            value={user.password}
            onChange={handleChange}
          />
          <button className="btnn" onClick={handleTogglePassword}>
            {showPassword ? "Hide Password" : "Show Password"}
          </button>

          <label>Re-Enter your Password</label>
          <input
            type="password"
            name="reEnterPassword"
            value={user.reEnterPassword}
            onChange={handleChange}
          />

          <button type="submit" onClick={signup}>
            Sign Up
          </button>
        </form>
      )}
    </div>
  );
};

export default Signup;
