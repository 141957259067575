import React from "react";
import Programs from "../component/Programs/Programs";
import Header from "../component/Header/Header";
import "./planes.css";
import Plansprogram from "../assets/planes&program.png";
import Timetable from "../component/Timetable/Timetable";
import ChecKselectedCom from "../component/ChecKselectedComp/ChecKselectedCom";
import Footer from "../component/Footer/Footer";
import service from "../component/Service/service";
import useFetch from "../ApiClient/GetApi";
import Plans from "../component/Plans/Plans";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
export const Planes = () => {

  const { data } = useFetch("users/all_plans");
  console.log(data);


  return (
    <div>
      <Header />

     

      <service />
     

      {/* <Planes /> */}
      <Plans plans={data?.data} />
      <Footer />
    {/* <Timetable /> */}
      {/* <Programs /> */} 


  
    </div>
  );
};
export default Planes;
