import React from "react";
import Header from "../Header/Header";
import "./Hero.css";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import { motion } from "framer-motion";
// import myVideo from "../../assets/video.mp4";

import NumberCounter from "number-counter";

const Hero = () => {
  const transition = { type: "spring", duration: 3 };
  return (
    <div className="hero">
      {/* <video id="my-video" autoPlay loop muted>
        <source src={myVideo} type="video/mp4" />
      </video> */}
      <Header />
      <div className="left-h">
        <div className="ad">
          <motion.div
            initial={{ left: "300px" }}
            whileInView={{ left: "8px" }}
            transition={transition}
          ></motion.div>
          <span>Your Body Hears Everything That Your Mind Says</span>
        </div>
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape</span>
            <span>Your</span>
          </div>
          <div>
            <span>
              Ideal <span className="stroke-text">body</span>
            </span>
          </div>
          <div>
            <span>
              In here we will help you to shape and build your ideal body and
              live up your life to fullest
            </span>
          </div>
        </div>
        <div className="figure">
          <div>
            <span>
              <NumberCounter end={23} start={1} delay="1" preFix="+" />
            </span>
            <span>expert coaches</span>
          </div>
          <div>
            <span>
              <NumberCounter end={3093} start={50} delay="1" preFix="+" />
            </span>
            <span>member joined</span>
          </div>
          <div>
            <span>
              <NumberCounter end={22} start={2} delay="1" preFix="+" />{" "}
            </span>
            <span>fitness program</span>
          </div>
        </div>

        <div className="hero-button">
          <button className="btn">Get started</button>
          <button className="btn">Learn more</button>
          {/* <div className="heart-rate">
            <img src={Heart} alt="" className="pngstyle" />
            <span>Heart Rate</span>
            <span>116 bpm</span> */}
        </div>
        {/* </div>
        <div className="right-h">
          <div className="calories">
            <img src={Calories} alt="" />
            <span className="c">Calories Burned </span>
            <span className="c">220 Kcal</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Hero;
