// import React from "react";
// import "./Footer.css";
// import Github from "./../../assets/github.png";
import Logo from "./../../assets/Fitlogo.png";
// import Linkdin from "./../../assets/linkedin.png";
// import Facebook from "./../../assets/facebook1.png";
// import Instagram from "./../../assets/instagram.png";
// // export const Footer = () => {
// //   return (
//     // <div className="footer-container">
//     //   <hr />
//     //   <div className="footer">
//     //     <div className="social-links">
//     //       <img src={Facebook} alt="" />
//     //       <img src={Instagram} alt="" />
//     //       <img src={Linkdin} alt="" />
//     //     </div>
//     //     <div className="logo-f">
//     //       <img src={Logo} alt="" />
//     //     </div>
//     //   </div>
//     // </div>

//     import { Box, Flex, Link, IconButton, Icon } from '@chakra-ui/react';
//     import { FaMapMarkerAlt, FaLinkedin } from 'react-icons/fa';

//     const Footer = () => {
//       return (
//         <Box bg="gray.800" color="white" py={6} px={8}>
//           <Flex justify="space-between" align="center">
//             <Box>
//               <Flex align="center">
//                 <Icon as={FaMapMarkerAlt} boxSize={6} mr={2} />
//                 <span>Your Gym Address, City, Country</span>
//               </Flex>
//               <Flex mt={2}>
//                 <Link href="https://www.linkedin.com/" isExternal>
//                   <IconButton
//                     icon={<Icon as={FaLinkedin} boxSize={5} />}
//                     variant="ghost"
//                     colorScheme="whiteAlpha"
//                     aria-label="LinkedIn"
//                   />
//                 </Link>
//               </Flex>
//             </Box>
//             <Box>
//               <span>&copy; 2023 FITHER Gym. All rights reserved.</span>
//             </Box>
//           </Flex>
//         </Box>

//   );
// };
// export default Footer;
import React from "react";
import {
  Box,
  Flex,
  Link,
  IconButton,
  Icon,
  Text,
  HStack,
} from "@chakra-ui/react";
import {
  FaMapMarkerAlt,
  FaTiktok,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  return (
    
    <Box bg="gray.800" color="white" py={6} px={8}>
      <Flex justify="space-between" align="center">
        <Flex direction="column">
          <Flex align="center" mb={4}>
            <Icon as={FaMapMarkerAlt} boxSize={6} mr={2} />
            <Text>Your Gym Address, City, Country</Text>
          </Flex>

          <HStack spacing={4}>
            <Link href="https://www.facebook.com/" isExternal>
              <IconButton
                icon={<Icon as={FaFacebookF} boxSize={5} />}
                variant="ghost"
                colorScheme="whiteAlpha"
                aria-label="Facebook"
              />
            </Link>
            <Link href="https://www.instagram.com/" isExternal>
              <IconButton
                icon={<Icon as={FaInstagram} boxSize={5} />}
                variant="ghost"
                colorScheme="whiteAlpha"
                aria-label="Instagram"
              />
            </Link>
            <Link href="https://twitter.com/" isExternal>
              <IconButton
                icon={<Icon as={FaTwitter} boxSize={5} />}
                variant="ghost"
                colorScheme="whiteAlpha"
                aria-label="Twitter"
              />
            </Link>
            <Link href="https://www.linkedin.com/" isExternal>
              <IconButton
                icon={<Icon as={FaLinkedin} boxSize={5} />}
                variant="ghost"
                colorScheme="whiteAlpha"
                aria-label="LinkedIn"
              />
            </Link>
            <Link href="https://www.youtube.com/" isExternal>
              <IconButton
                icon={<Icon as={FaYoutube} boxSize={5} />}
                variant="ghost"
                colorScheme="whiteAlpha"
                aria-label="YouTube"
              />
            </Link>
            <Link href="https://www.tiktok.com/" isExternal>
              <IconButton
                icon={<Icon as={FaTiktok} boxSize={5} />}
                variant="ghost"
                colorScheme="whiteAlpha"
                aria-label="TikTok"
              />
            </Link>
            {/* Add more social media icons and links here */}
          </HStack>
        </Flex>
        <Box>
          <Text>&copy; 2023 FITHER Gym. All rights reserved.</Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
