import React from "react";
import "./Plans.css";
import { plansData } from "../../data/plansData";
import { plansDatapakistan } from "../../data/plansData";
import { Box, Text, Image, Button } from "@chakra-ui/react";
import whiteTick from "../../assets/whiteTick.png";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import useFetch from "../../ApiClient/GetApi";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import backgroundImage from "../../assets/Fitlogo.png"; // Import your background image
import { useState } from "react";
const Workout = (props) => {
  const navigate = useNavigate();
  const {
    isOpen: ModalOpen,
    onOpen: ModalOnOpen,
    onClose: ModalOnClose,
  } = useDisclosure();
  const { data } = useFetch("users/workout");
  const [time,setTime] = useState([]);
  const open_modal = (value) => {
    setTime(value)
    ModalOnOpen();
  };

  const plan_details = (planId) => {
    navigate(`/plan_details/${planId}`);
  };

  // const join_meeting = (link) =>{
  //   window.open(link, '_blank');
  // }

  return (
    <div>
      <Header />
      <Box textAlign="center" mb={10} mt={12}>
        <Text fontSize="2xl" fontWeight="bold">
          Ready to Start Your Journey Now With Us
        </Text>
        <Text fontSize="2xl" fontWeight="bold">
          Workout Plan Starting from 20
        </Text>
      </Box>

      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "contain", // Adjust the background size property
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          padding: "16px",
          borderRadius: "8px",
          width: "90%", // Adjust the width of the timetable component
          height: "300px", // Adjust the height of the timetable component
        }}
      >
        <Table
          size="sm"
          bgColor="rgba(255, 255, 255, 0.8)"
          borderRadius="md"
          boxShadow="md"
        >
          <Thead>
            <Tr>
              <Th>Title</Th>
              <Th>View Timing</Th>

              <Th>Join Class</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.data?.map((plan) => (
              <Tr>
                <Td>{plan.title}</Td>
                <Td onClick={()=>open_modal(plan.Times)}>View Timeing</Td>
                <Td>
                  <button onClick={()=>plan_details(plan?.id)}>Join now</button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>

      <Modal onClose={ModalOnClose} isOpen={ModalOpen} size="3xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />

          <ModalBody className="bg-white rounded-lg">
          <h4>Timing Schedule for Workout</h4>
            <Table>
               
              <Thead>
                <Tr>
                  <Th>Day</Th>
                  <Th>Start</Th>
                  <Th>End</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                    time?.map((tim)=>(
                        <Tr>
                        <Td>{tim.day}</Td>
                        <Td>{tim.start}</Td>
                        <Td>{tim.end}</Td>
                      </Tr>
                    ))
                }
               
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Workout;
