import React from "react";
import { DietPlanProgram } from "../component/DietPlanProgram/DietPlanProgram";
import Footer from "../component/Footer/Footer";
import Hero from "../component/Hero/Hero";
import Join from "../component/Join/Join";
import Plans from "../component/Plans/Plans";
import Programs from "../component/Programs/Programs";
import Resons from "../component/Resons/Resons";
import Testimonials from "../component/Testimonials/Testimonials";
import ServicesComponent from "../component/WHY-us/Servicecompoent";
import useFetch from "../ApiClient/GetApi";

const Home = () => {

  const {data} = useFetch("users/home");
  console.log(data?.data)
  return (
    <div className="App">
      <Hero />
      <ServicesComponent/>
      <Programs />
      <DietPlanProgram/>
      <Resons />
      <Plans plans={data?.data?.plans} />
      <Testimonials />
      <Join />
      <Footer />
    </div>
  );
};

export default Home;
