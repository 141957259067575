import React from "react";
import DietPlanData from "../../data/DietPlanData";
import RightArrow from "../../assets/rightArrow.png";
export const DietPlanProgram = () => {
  return (
    <div className="programs" id="programs">
     
      <div
        class="programs-header"
        style={{ backgroundColor: "white", color: "#b8c933" }}
      >
        Dietary Services 
        
      </div>
      <h1 className="programs-header" style={{backgroundcolor:"black", color:"white",}} >Health Issue Specifically</h1>
      <div className="program-categories">
        {DietPlanData.map((program, index) => (
          <div className="category" key={index}>
            {program.icon}
            <span>{program.heading}</span>
            <span>{program.details}</span>
            <span>{program.features}</span>
            <div className="join-now">
              <span>Join Now</span>
              <img src={RightArrow} alt="Right Arrow" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
